import React, { useCallback, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { ReactComponent as RRALoginLogoIcon } from '../../../assets/images/svgs/rra-login-logo.svg';
import { ReactComponent as PoweredByFindem } from '../../../assets/images/svgs/powered-by-findem.svg';
import { Divider, Icon, theme } from 'maui';
import { IAuthenticationProps, LOGIN_TYPE, useAuthenticateUser } from '../../../customHooks/useCurrentUser';
import { DividerText, HeadMessage, LoginContainer, MutedSubTitle, PageContainer, StyledButton, StyledRow, StyledSpan } from './Login.styled';

const LoginForm = () => {
  const [loginType, setLoginType] = useState<LOGIN_TYPE | undefined>(undefined);

  const [formValues, setFormValues] = useState<IAuthenticationProps>({
    email: null,
    password: null,
  })

  const { isLoggingLoading } = useAuthenticateUser({
    email: formValues.email,
    password: formValues.password,
    loginType
  })

  const handleSubmit = useCallback((formValues: IAuthenticationProps) => {
    setLoginType(LOGIN_TYPE.BASIC_AUTH);
    setFormValues(formValues);
  }, [])


  const handleGoToFindem = useCallback(() => {
    window.open('https://findem.ai/', '_blank');
  }, []);

  const handleAzureLogin = useCallback(async () => {
    setLoginType(LOGIN_TYPE.MICROSOFT_SSO)
  }
  , []);

  return (
    <PageContainer>
      <LoginContainer>
        <div>
          <RRALoginLogoIcon style={{width: 100, height: 40}} />
          <StyledRow>
            <HeadMessage>Welcome back</HeadMessage>
            <MutedSubTitle>Sign in to your AI powered leadership advisory workspace.</MutedSubTitle>
          </StyledRow>
          <Form
            name="login"
            initialValues={{ remember: true }}
            onFinish={handleSubmit}
            layout="vertical"
            style={{
              width: "100%"
            }}
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input
                placeholder="Email address"
                prefix={<Icon icon='email' type='outlined' $color={theme.colors['grey-500']} />}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input
                type="password"
                placeholder="Password"
                prefix={<Icon icon='password' type='outlined' $color={theme.colors['grey-500']} />}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block loading={isLoggingLoading}>
                Sign In
              </Button>
            </Form.Item>
            <Divider style={{ margin: "32px 0px"}}>
              <DividerText>OR</DividerText>
            </Divider>
            <Form.Item>
              <StyledButton type="default" block icon={<RRALoginLogoIcon style={{ width: 50}} />} onClick={handleAzureLogin}>
                Continue with RRA SSO
              </StyledButton>
            </Form.Item>
          </Form>
          <div style={{
            display: 'flex',
            justifyContent: "center",
            width: "100%",
            marginTop: "112px"
          }}>
            <StyledSpan onClick={handleGoToFindem}><PoweredByFindem width={190} height={42}/></StyledSpan>
          </div>
        </div>
      </LoginContainer>
    </PageContainer>
  );
};

export default LoginForm;
